@import '../../styles/customMediaQueries.css';

.root {
  padding: 10px;
  position: relative;

  gap: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  cursor: pointer;
  border-radius: 10px;
  transition: all 0.1s ease;
  background-color: var(--colorWhite);
  border: 2px solid var(--colorLightGray);

  @media (--viewportMedium) {
    &:hover {
      border: 2px solid var(--colorOffWhite);
      box-shadow: 0 4px 8px -2px rgb(0 0 0 / 0.1), 0 4px 8px -2px rgb(0 0 0 / 0.1);
    }
  }
}

.content {
  gap: 1rem;
  display: flex;
}

.aspectRatioWrapper {
  flex-grow: 1;
  cursor: pointer;
  border-radius: 8px;
  background: var(--colorGrey100);

  @media (min-width: 300px) and (max-width: 359px) {
    width: 112px;
    height: 92px;
  }
}

.rootForImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.info {
  width: 75%;
  height: auto;

  gap: 1rem;
  display: flex;
  flex-direction: column;
  /* background-color: yellowgreen; */

  @media (min-width: 360px) {
    width: 60%;
  }

  @media (min-width: 992px) {
    width: 71.12%;
  }

  @media (min-width: 1200px) {
    width: 62.195%;
  }

  @media (min-width: 1400px) {
    width: 67.69%;
  }
}

.favouritesSpan {
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  padding: 5px;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  border-radius: 50%;
  background: transparent;
  border: 2px solid var(--colorLightGray);

  & svg {
    width: 100%;
    height: 100%;

    & > path:first-child {
      fill: #ed553b;
    }

    & > path:last-child {
      fill: #fff;
    }
  }
}

.favourited {
  border-color: #ed553b;

  & svg {
    & > path:last-child {
      fill: #ed553b;
    }
  }
}

.favouriteLoading {
  background-color: #ed553b40;
}

.title {
  /* Font */
  composes: textSmall from global;
  margin: 0;
  width: 80%;

  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: var(--colorOrange);
  /* background-color: aqua; */
}

.viewMoreInfo {
  margin-top: 3px;
  font-size: 13px;
}

.longWord {
  /* fallback option */
  word-break: break-all;

  /* use break-word if available */
  hyphens: auto;
  word-break: break-word;
  /* background-color: red; */
}

.productRating {
  gap: 10px;
  display: flex;
  align-items: center;

  & svg {
    width: 20px;
    height: 20px;
    fill: var(--colorDarkBlue);
  }
}

.ratingIcons {
  display: flex;
  align-items: center;
}

.rating {
  font-size: 1rem;
  font-weight: 800;
  color: var(--colorGreen);
}

.descP {
  width: 90%;
  margin: 0 !important;
  padding: 0 !important;

  font-size: 15px;
  font-weight: 700;
  line-height: 18px;

  @media (--vwMaxM) {
    width: 80%;
  }
}

.author {
  composes: p from global;
  gap: 10px;
  margin: 0;
  padding: 0;
  display: flex;

  @media (--viewportMedium) {
    width: 100%;
    color: var(--colorGrey700);
  }
}

.authorAvatar {
  display: flex;
  align-self: flex-start;
}

.providerAvatarMedium {
  display: none;

  @media (min-width: 768px) {
    display: flex;
    align-self: flex-start;
  }
}

.providerAvatarSmall {
  display: flex;
  align-self: flex-start;

  @media (min-width: 768px) {
    display: none;
  }
}

.authorInformation {
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: red; */
}

.authorCompany {
  width: max-content;

  gap: 6px;
  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: var(--colorBlue);

  & svg {
    width: 1rem;
  }
}

.authorName {
  margin-top: -4px;

  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: var(--colorOrange);
}

.authorIsVerified {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: var(--colorTextLightGray);
}

.websiteLink {
  gap: 4px;
  display: flex;
  align-items: center;

  font-size: 14px;

  & > svg {
    fill: none;
    width: 19px;
    height: 19px;
    stroke: var(--colorBlue);

    & > path {
      stroke: var(--colorBlue);
    }
  }
}

.serviceChipContainer {
  gap: 6px;
  display: flex;
  flex-wrap: wrap;

  & div {
    padding: 3px 6px;

    color: white;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    border-radius: 9999px;
    background-color: var(--colorOrange);
  }
}

.utility {
  gap: 10px;
  display: flex;
  flex-direction: column;
  /* background-color: bisque; */
}

.locationP {
  margin: 0;
  padding: 0;

  gap: 4px;
  display: flex;
  align-items: center;

  font-size: 12px;
  font-weight: 500;
  color: #9d9d9d;
  line-height: 1rem;

  & svg {
    min-width: 20px;
    min-height: 20px;
    align-self: flex-start;
  }
}

/* .buttonWrapper {
  // old style
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-areas:
    'b1'
    'b2'
    'b3';

  @media (min-width: 420px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'b1 b1'
      'b2 b3';
  }

  @media (min-width: 992px) {
    grid-template-areas: 'b1 b2 b3';
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1200px) and (max-width: 1399px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'b1 b1'
      'b2 b3';
  }
} */

/* .buttonWrapper {
  // new design
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-areas:
    'b1'
    'b2'
    'b3'
    'b4';

  @media (min-width: 420px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'b1 b2'
      'b3 b4';
  }
} */

.buttonWrapper {
  /* // new style with WhatsApp */
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'b1 b1'
    'b3 b4'
    'b2 b2';

  @media (--vwM) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      'b1 b1 b1'
      'b2 b3 b4';
  }

  @media (--vwL) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      'b1 b1 b1'
      'b2 b3 b4';
  }

  /* @media (min-width: 1200px) and (max-width: 1399px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      'b1 b1 b1'
      'b2 b3 b4';
  } */
}

.getDirection {
  grid-area: b1;
  padding: 10px 8px;

  gap: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  border-radius: 8px;
  white-space: nowrap;
  transition: all 200ms ease-in-out;
  border: 2px solid var(--colorLightGray);

  color: var(--marketplaceColor);
  background-color: var(--colorWhite);

  & svg {
    width: 22px;
    height: 22px;
  }

  &:hover {
    color: var(--colorDarkBlue);
    border: 2px solid var(--marketplaceColorDark);

    & svg > path,
    & svg > stroke {
      fill: var(--colorBlack);
    }
  }

  @media (max-width: 575px) {
    padding: 6px;
  }
}

.sendEnquiry {
  width: 100%;
  grid-area: b2;
  padding: 12px 8px;

  border: 0;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  border-radius: 8px;
  white-space: nowrap;
  transition: all 200ms ease-in-out;
  background-color: var(--colorBlue);

  &:hover {
    background-color: var(--colorDarkBlue);
  }

  @media (max-width: 575px) {
    padding: 8px;
  }
}

.call {
  grid-area: b3;
  padding: 12px 8px;

  gap: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  cursor: pointer;
  border-radius: 8px;
  white-space: nowrap;
  color: var(--marketplaceColor);
  transition: all 200ms ease-in-out;
  background-color: var(--colorLightGray);

  & svg {
    width: 22px;
    height: 22px;
    fill: transparent;
  }

  &:hover {
    color: var(--colorDarkBlue);
    background-color: var(--colorGrey200);

    & a {
      text-decoration: none;
      color: var(--colorDarkBlue);
    }

    & svg > path {
      fill: var(--colorDarkBlue);
    }
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus,
  &:disabled:active {
    border: none;
    background-color: var(--colorGrey100);

    & svg {
      & path:first-child {
        fill: transparent;
        stroke: #4a4a4f;
      }

      & path:last-child {
        fill: #4a4a4f;
      }
    }

    & a,
    & span {
      color: #4a4a4f;
    }
  }

  @media (max-width: 575px) {
    padding: 8px;
  }
}

.whatsapp {
  composes: call;
  grid-area: b4;
  background-color: var(--colorWhite);
  border: 2px solid var(--colorGreen);

  & svg {
    width: 26px;
    height: 26px;
    fill: transparent;

    & path:first-child {
      stroke: var(--colorGreen);
    }

    & path:last-child {
      fill: var(--colorGreen);
    }
  }

  & a,
  & span {
    color: var(--colorGreen);
  }

  &:hover {
    background-color: var(--colorGreen);

    & svg {
      & path:first-child {
        fill: transparent;
        stroke: var(--colorWhite);
      }

      & path:last-child {
        fill: var(--colorWhite);
      }
    }

    & a,
    & span {
      color: var(--colorWhite);
    }
  }

  @media (--vwMaxL) {
    & a,
    & span {
      display: none;
    }
  }
}

.callNotAvailable {
  opacity: 0.4;
  cursor: not-allowed;
}

/* CSS for those sections which are redundant based on width */
.authorInvisibleFrom1200 {
  display: block;

  @media (min-width: 1200px) {
    display: none;
  }
}

.authorVisibleFrom1200 {
  display: none;

  @media (min-width: 1200px) {
    display: block;
  }
}

.visibleUtilitySection {
  @media (min-width: 0px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 1200px) {
    display: none;
  }
}

.invisibleUtilitySection {
  @media (min-width: 768px) and (max-width: 1199px) {
    display: none;
  }
}
/* CSS for those sections which are redundant based on width */
